import React from 'react'
// import { HamburgerButton } from 'react-hamburger-button'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import {
  faExternalLinkAlt,
  faPhone,
  faHeadset,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import siteData from '../../content/siteData.yaml'

import './styles.sass'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Logo from '../../components/Logo'

class PageHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  handleMenuClick() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  render() {
    return (
      <header
        className={`instar-page-header s--menu-open-${this.state.menuOpen}`}
      >
        <div className="container permanent-bar">
          <Logo />
          {/* {!this.state.menuOpen && ( */}
          <div className="contact text-right order-2">
            <OutboundLink
              className="btn btn-nav-main"
              href={siteData.links.support}
            >
              <FontAwesomeIcon icon={faHeadset} className="icon" />
              <span className="d-none d-md-inline ml-md-3">
                {siteData.links.supportLinkText}
              </span>
            </OutboundLink>
            <a
              href={`tel:${siteData.contact.tel}`}
              className="btn btn-nav-mainy d-md-none"
            >
              <FontAwesomeIcon icon={faPhone} className="icon" />
            </a>
            {/* <a href={`tel:${siteData.contact.tel}`} className="btn btn-outline-primary d-none d-md-block">
                  <span className="">{siteData.contact.telLinkText}</span>
                </a> */}
            <Link
              to={siteData.links.form}
              className="btn btn-nav-mainy d-md-none"
            >
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
            </Link>
            <Link
              to={siteData.links.form}
              className="btn btn-outline-primary d-none d-md-block"
            >
              <span className="">{siteData.links.formLinkText}</span>
            </Link>
            <OutboundLink
              className="d-none d-sm-block btn btn-primary mr-3 ml-3"
              href={siteData.links.console}
            >
              <span className="d-md-inline">
                {siteData.links.consoleLinkText}
              </span>
            </OutboundLink>
          </div>
          {/* )} */}
          {/* <div className="menu-button text-right d000-block d000-md-none order-3">
            <HamburgerButton
              open={this.state.menuOpen}
              onClick={this.handleMenuClick}
              width={20}
              height={16}
              strokeWidth={2}
              color="#69727A"
              animationDuration={0.5}
            />
          </div> */}
        </div>
        {this.state.menuOpen && (
          <div className="container menu-expansion">
            <div className="major">
              <div className="container-fluid">
                <div className="top-section">
                  <div className="row">
                    {/* <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                        <Link to="/" className="link secondary">
                          Home
                        </Link>
                        <div className="description">Premium Hosting</div>
                      </div> */}

                    <div className="col-12 col-md-6 col-lg-4 offset-lg-1">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-12">
                          <Link to="/blog" className="link secondary">
                            Features &amp; Services
                          </Link>
                          <div className="description">Premium Hosting</div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-12">
                          <Link to="/blog" className="link secondary">
                            FAQs
                          </Link>
                          <div className="description">
                            Frequently Asked Questions
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="row">
                        <div className="col-12">
                          <Link to="/blog" className="link secondary">
                            Blog
                          </Link>
                          <div className="description">
                            Latest articles:
                            <ul>
                              <li>
                                <Link
                                  to="/blog/the-hidden-cost-decoupling"
                                  className="link"
                                >
                                  The Hidden Costs of Decoupling
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/blog/the-hidden-cost-decoupling"
                                  className="link"
                                >
                                  2-Factor is so last year, Ironstar is now
                                  secured with 3FA
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 offset-lg-1">
                      <Link to="/docs" className="link secondary">
                        Documentation
                      </Link>
                      <div className="description">
                        An overview of Ironstar services
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <a
                        rel="noreferrer"
                        href="https://tokaido.io"
                        className="secondary tokaido"
                      >
                        Tokaido
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          className="ml-2 icon icon--small"
                        />
                      </a>
                      <div className="description">
                        Open-source Drupal environment
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row bottom-section">
                  <div className="col-12 offset-lg-1 contact">
                    <a
                      className="secondary mr-5"
                      href={`tel:${siteData.contact.tel}`}
                    >
                      {siteData.contact.telLinkText}
                    </a>
                    <a href={`mailto:${siteData.contact.email}`}>
                      {siteData.contact.emailLinkText}
                    </a>
                  </div>
                </div>
                {/* <div className="col-12 col-md-5 no-border">
                      <a
                        href={`mailto:${siteData.contact.email}`}
                        className="btn btn-outline-primary"
                      >
                        {siteData.contact.emailLinkText}
                      </a>
                    </div> */}
              </div>
            </div>
            {/* <div className="d-none d-md-block blog-post col-md-4">
                <div className="container-fluid">
                  <div className="row top-section">
                    <div className="col-11 offset-1 title">
                      Early Rendering:<br />
                      A Lesson in Debugging <br/>
                      Drupal 8
                    </div>
                  </div>
                  <div className="row bottom-section">
                    <div className="col-11 offset-1">
                      <Link to="/blog">
                        <span className="mr-3">Read the blog</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        )}
      </header>
    )
  }
}

export default PageHeader
