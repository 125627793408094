import React from 'react'
import Helmet from 'react-helmet'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import PageHeader from '../containers/PageHeader'
import PageFooter from '../containers/PageFooter'
import ContactForm from '../components/ContactForm'
import siteData from '../content/siteData.yaml'

export default () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
    <div className="instar-contactform-page">
      <Helmet>
        <title>Ironstar - Premium Hosting</title>
        <meta name="description" content="Premium hosting" />
        <meta
          name="keywords"
          content="drupal, hosting, ironstar, node.js, nodejs, laravel"
        />
      </Helmet>

      <PageHeader />
      <main className="page-container container">
        <section className="page__header">
          <div className="row">
            <h1 className="col h1">Contact Ironstar</h1>
          </div>
        </section>
        <div className="row">
          <div className="col-lg-9 page__content">
            <section className="page__content__inner">
              <p className="lead">
                Get in touch with us to discuss your Drupal, Laravel or NodeJs
                website solution hosting needs. We're here to help.
              </p>
              <ContactForm />
            </section>
          </div>
          <aside className="col-lg-3 page__aside">
            <dl>
              <dt className="h6">Phone</dt>
              <dd className="small">
                <p>
                  <a className="" href={`tel:${siteData.contact.tel}`}>
                    {siteData.contact.telLinkText}
                  </a>
                </p>
                &nbsp;
              </dd>
              <dt className="h6">Email</dt>
              <dd className="small">
                <p>
                  Use the contact form or email us directly at{' '}
                  <a className="" href={`mailto:${siteData.contact.email}`}>
                    {siteData.contact.emailLinkText}
                  </a>
                </p>
                &nbsp;
              </dd>
            </dl>
          </aside>
        </div>
      </main>
      <PageFooter />
    </div>
  </GoogleReCaptchaProvider>
)
