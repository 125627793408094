import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import './styles.sass'

// const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

const ContactForm = () => {
  const recaptchaAction = 'contact_form'
  const formReference = 'contactForm'
  const MAX_TOKEN_TRIES = 0 // 0 = no limit
  const [tokenTries, setTokenTries] = useState(0)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    reset,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
      isSubmitSuccessful,
      submitCount,
    },
  } = useForm()

  const getReCaptchaToken = async () => {
    if (!executeRecaptcha) {
      // TODO: Wait and retry?
      throw new Error('Unable to retrieve token yet.', {
        cause: { ref: 'AZJccV' },
      })
    }

    setTokenTries(tokenTries + 1)
    const result = await executeRecaptcha(recaptchaAction)
    return result
  }

  const onFormSubmit = async (data) => {
    try {
      const captcha = await getReCaptchaToken()

      if (!captcha) {
        throw new Error('Unable to retrieve token', {
          cause: {
            ref: 'WnZclW',
            related: { captcha },
          },
        })
      }

      await fetch(`${process.env.ARIMA_BASE_URL}/website/contactform/send`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formName: formReference,
          ...data,
          token: captcha || '',
          tokenTries: tokenTries + 1,
          submitCount: submitCount + 1,
        }), // body data type must match "Content-Type" header
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((body) => {
              const text = JSON.parse(body)
              throw new Error('Unable to complete sending', {
                cause: {
                  ref: 'SsGm5u',
                  message: text.message || text,
                  related: response,
                },
              })
            })
          } else {
            return response.json()
          }
        })
        .then(() => {
          // Success, reset form
          reset()
        })
        .catch((err) => {
          if (err instanceof Error) {
            throw err
          } else {
            throw new Error(
              'An error occurred while submitting the contact form',
              {
                cause: {
                  ref: 'sVlHjz',
                  related: err,
                },
              }
            )
          }
        })
    } catch (err) {
      if (err instanceof Error) {
        handleFormError(err)
      } else {
        handleFormError({
          ref: 'JK5mXT',
          message: 'An unexpected error has occurred',
          related: err,
        })
      }
    }
  }

  const onTryAgain = () => {
    clearErrors('submit')
    if (
      MAX_TOKEN_TRIES !== 0 ||
      (MAX_TOKEN_TRIES > 0 && tokenTries < MAX_TOKEN_TRIES)
    ) {
      handleSubmit(onFormSubmit)()
    } else {
      handleFormError({
        ref: 'IyFFH2',
        message: 'Maximum token tries reached',
        related: { tokenTries, MAX_TOKEN_TRIES },
      })
    }
  }

  const handleFormError = (err) => {
    // handleFormError can take an Error object or a dev constructed Object with the following params:
    //   @param {Error} (from Standard built-in object)
    //     @param {string} name - type of Error thrown (always "Error")
    //     @param {string} message - a message reference of the error
    //     @param {string} cause - a message reference of the error
    //   @param {Object}
    //     @param {string} ref - a unique code to find the source of error
    //     @param {string} message - a message reference of the error
    //     @param {any} related - any information related to the error, ie response, additional info.

    let displayMessage =
      (err && err.message) || 'An unexpected error has occured'

    if (process.env.NODE_ENV !== 'production')
      if (err instanceof Error) {
        console.error('ERROR', err.name + ': ' + err.message, err.cause)
      } else {
        console.error('OTHER', err)
      }

    setError('submit', {
      type: 'manual',
      message: `${displayMessage}`,
    })
  }

  return (
    <form
      data-netlify="true"
      name={formReference}
      method="post"
      onSubmit={handleSubmit(onFormSubmit)}
      action="/contact-thank-you"
    >
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input {...register('name')} type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email address *</label>
        <input
          {...register('email', { required: 'Email address is required' })}
          type="email"
          className={`form-control ${errors.email ? 'border-danger' : ''}`}
          aria-describedby="emailHelp"
          required
        />
        <small
          id="emailHelp"
          className={`form-text ${errors.email ? 'text-danger' : 'text-muted'}`}
        >
          This field is mandatory
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone</label>
        <input {...register('phone')} type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="company">Company</label>
        <input {...register('company')} type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea
          {...register('message')}
          className="form-control"
          rows="3"
        ></textarea>
      </div>
      <div className="form-group">
        <button
          className={`btn btn-primary ${isSubmitting ? 's--sending' : ''}`}
          disabled={isSubmitting}
          type={isSubmitted && errors.submit ? 'button' : 'submit'}
          onClick={isSubmitted && errors.submit ? onTryAgain : () => {}}
        >
          {(isSubmitting && 'Sending...') ||
            (isSubmitted && errors.submit && 'Try Again') ||
            'Send'}
        </button>
      </div>
      {isSubmitted &&
        !isSubmitting &&
        ((isSubmitSuccessful && (
          <div className="alert alert-success" role="alert">
            Your message was successfully sent.
          </div>
        )) || (
          <div className="alert alert-danger" role="alert">
            We're sorry, an unexpected error has occurred.
            <br />
            If the issue persists, please contact us via phone or email
            directly.
          </div>
        ))}
    </form>
  )
}

export default ContactForm
